import { Card, Spinner } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import { Table } from "shared/components";
import { StatusReportResponse } from "shared/store-zustand/get-status-report/getStatusReport";

type TasksProps = {
  itemsTasks: StatusReportResponse | null;
  loading: boolean;
}

const Tasks = ({ itemsTasks, loading }: TasksProps) => {
  const { t } = useTranslation();

  if (!itemsTasks && loading) {
    return (
      <Card style={{ height: '400px' }}>
        <Card.Body className="text-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">{t('Loading...')}</span>
          </Spinner>
        </Card.Body>
      </Card>
    );
  }

  return (
    <>

      <Table style={{ borderTop: '1px solid #8c8c8c' }}>
        <Table.Td style={{ textOverflow: 'ellipsis', fontWeight: '600', color: '#5E6E82', paddingTop: '8%' }}>{t('Description')}</Table.Td>
        <Table.Td style={{ textOverflow: 'ellipsis', fontWeight: '600', color: '#5E6E82' }}>{t('Status Mitigation')}</Table.Td>
        {itemsTasks?.scopeManagementList.map((item, index) => (
          <Table.Tbody>
            <Table.Tr style={{ borderBottom: '1px solid #ccc' }} key={index}>
              <Table.Td title={item.description} style={{
                maxWidth: '50px',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                color: '#5E6E82'
              }}>{item.description}</Table.Td>
              <Table.Td title={item.description} style={{
                maxWidth: '50px',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                color: '#5E6E82'
              }}>{item.description}</Table.Td>
            </Table.Tr>
          </Table.Tbody>
        ))}
      </Table>

    </>
  )
}

export default Tasks;
