import { btoApi } from 'bto_now/api/client';
import { AxiosResponse } from "axios";

export type GetAllPhasesResponse = Array<{
  phaseExecutionId: number;
  name: string;
}>

export async function getAllPhases(): Promise<GetAllPhasesResponse> {
  try {
    const response: AxiosResponse<GetAllPhasesResponse> = await btoApi.get('/api/Phase/GetAllPhases');

    const translatedData = response.data.map((phase) => ({
      ...phase,
      name: phasesTraduction(phase.name)
    }));

    return translatedData;
  } catch (error) {
    console.error('Error fetching phases:', error);
    throw error; 
  }
}

const phasesTraduction = (phase: string) => {
  switch (phase) {
    case 'ESTUDO':
      return 'Study';
    case 'PLANEJAMENTO':
      return 'Planning';
    case 'EXECUÇÃO':
      return 'Execution';
    case 'HOMOLOGAÇÃO':
      return 'Homologation';
    case 'IMPLANTAÇÃO':
      return 'Implementation';
    case 'ENCERRAMENTO':
      return 'Closing';
    default:
      return 'Unknown';
  }
}
