import { Col, Modal, Row } from "antd";
import * as echarts from 'echarts';
import { useEffect, useState } from "react";
import { IoGrid } from "react-icons/io5";
import { Link, useParams } from "react-router-dom";
import { ComponentHeader } from "shared/layouts";
import GraphComponent from "shared/components/Graph";
import KPIComponent from "shared/components/KPI";
import { PALETE, POST_API, getToken, getUser, space } from "../../../../../services";
import { useTranslation } from "react-i18next";
import { Breadcrumb, PageHeader } from 'shared/components';
import { getDashOperacional } from "bto_now/api/portfolio/getDashOperacional";
// import HeaderComponent from "../../layouts/Header";

const MainInitiativeOperational = () => {

    const { MAIN } = useParams()

    const [ blocks, setBlocks ] = useState([]);
    // const [dashData, setDashData] = useState({});

    const routes = [ 
        {title: <IoGrid size={20} style={{margin: 0}} />},
        {title: <Link to="/dashboard/mng">Home</Link>},
        {title: <Link to="/dashboard">Portfolio Mng</Link>},
        {title: <Link to={`/dashboard/initiative/${MAIN}`}>Program Mng</Link>},
        {title: 'Program Operational Mng'}
    ]

    const onload = () => {
        POST_API('/operationalProgramme.php', { token: getToken(), user: getUser(), main: MAIN }).then(rs => rs.json()).then(res => {
            if (res.return) {
                setBlocks(res.data)
            } else { Modal.warning({ title: 'Atenção', content: res.msg }) }
        }).catch(() => null)
    }

    // const onLoad = async () => {
    //     try {
    //         const response = await getDashOperacional({ user: getUser() }); 
    //         if (response.data) {
    //             setDashData(response.data);
    //         }
    //     } catch (error) {
    //         Modal.warning({ title: 'Atenção', content: 'Erro ao carregar os dados do Dashboard Operacional.' });
    //     }
    // };

    useEffect(() => {
        // onLoad()
        onload()
    }, [])

    const loadGraphBar01 = () => {

        var chartDom = document.getElementById('graphBar01');
        var myChart = echarts.init(chartDom);
        var option;

        option = {
            textStyle: { fontFamily: 'Poppins' },
            color: [ PALETE.C03, PALETE.C01 ], dataZoom: [ { type: 'inside' } ],
            legend: { center: 'center', itemWidth: 14, bottom: '-5px', width: '100%' },
            tooltip: { trigger: 'axis', axisPointer: { type: 'shadow' } },
            grid: { left: '5px', right: '5px', bottom: '30px', top: '20px', containLabel: true },
            xAxis: [ { type: 'category', data: blocks?.GRAPH01?.eixoX, axisTick: { alignWithLabel: true } } ],
            yAxis: [ { type: 'value' } ],
            series: [
                { name: 'On-Going', barWidth: 50, type: 'bar', stack: 'total', data: blocks?.GRAPH01?.data?.['01'], label: { show: true, position: 'inside' }, itemStyle: { borderRadius: [8,8,0,0] } },
                { name: 'Finished', barWidth: 50, type: 'bar', stack: 'total', data: blocks?.GRAPH01?.data?.['02'], label: { show: true, position: 'inside' }, itemStyle: { borderRadius: [8,8,0,0] } },
            ]
        };

        option && myChart.setOption(option);

    }

    const loadGraphPizza01 = () => {

        var chartDom = document.getElementById('graphPizza01');
        var myChart = echarts.init(chartDom);
        var option;

        option = {
            textStyle: { fontFamily: 'Poppins' },
            color: [ PALETE.C01, PALETE.C02, PALETE.C03 ],
            legend: {  orient: 'horizontal', center: 'center', itemWidth: 14, width: '100%', bottom: 0 },
            tooltip: { trigger: 'axis', axisPointer: { type: 'shadow' } },
            grid: { left: '5px', right: '5px', bottom: '24px', top: '20px', containLabel: true },
            series: [
                {
                    name: 'Percentual',
                    type: 'pie',
                    radius: ['40%', '70%'],
                    avoidLabelOverlap: false,
                    label: { show: false, position: 'center' },
                    emphasis: { label: { show: true, fontSize: 20, fontWeight: 'bold', formatter: (v) => `${v.value}%` } },
                    labelLine: { show: false },
                    itemStyle: { borderRadius: 8, borderColor: '#fff', borderWidth: 2 },
                    data: blocks?.GRAPH02
                },
            ]
        };

        option && myChart.setOption(option);

    }

    const loadGraphBar02 = () => {

        var chartDom = document.getElementById('graphBar02');
        var myChart = echarts.init(chartDom);
        var option;

        option = {
            textStyle: { fontFamily: 'Poppins' },
            color: [ PALETE.C01 ], dataZoom: [ { type: 'inside' } ],
            tooltip: { trigger: 'axis', axisPointer: { type: 'shadow' } },
            grid: { left: '5px', right: '5px', bottom: '10px', top: '26px', containLabel: true },
            xAxis: [ { type: 'category', data: blocks?.GRAPH03?.eixoX, axisTick: { alignWithLabel: true } } ],
            yAxis: [ { type: 'value' } ],
            series: [
                { name: 'End Date', barWidth: 60, type: 'bar', data: blocks?.GRAPH03?.data, label: { show: true, position: 'top', backgroundColor: '#4B4672', color: '#FFF', borderRadius: 3, padding: 3 }, itemStyle: { borderRadius: [8,8,0,0] } },
            ]
        };

        option && myChart.setOption(option);

    }

    const loadGraphBar03 = () => {

        var chartDom = document.getElementById('graphBar03');
        var myChart = echarts.init(chartDom);
        var option;

        option = {
            color: [ PALETE.C01 ], dataZoom: [ { type: 'inside' } ],
            tooltip: { trigger: 'axis', axisPointer: { type: 'shadow' }, valueFormatter: (v) => `${(v).toLocaleString('pt-BR')}%` },
            grid: { left: '5px', right: '5px', bottom: '0px', top: '20px', containLabel: true },
            xAxis: [ { type: 'category', axisLabel: { rotate: 30, fontSize: 10, width: 60, overflow: 'truncate'  }, data: blocks?.GRAPH04?.eixoX, axisTick: { alignWithLabel: true } } ],
            yAxis: [ { type: 'value', axisLabel: { formatter: (v) => `${(v).toLocaleString('pt-BR')}%` } } ],
            series: [
                { 
                    name: 'Time Based', barWidth: 40, type: 'bar', 
                    data: blocks?.GRAPH04?.data,
                    label: { formatter: (v) => `${(v.value).toLocaleString('pt-BR')}%`, show: true, position: 'top', backgroundColor: '#4B4672', color: '#FFF', borderRadius: 3, padding: 3 }, itemStyle: { borderRadius: [8,8,0,0] },
                    markLine: { data: [
                        { yAxis: 100, symbol: 'none', lineStyle: { color: '#E77E9F' }, label: { show: true, fontSize: '9px', position: 'insideStartTop', formatter: () => '(+100%)' }},
                        { yAxis: 75, symbol: 'none', lineStyle: { color: '#E77E9F' }, label: { show: true, fontSize: '9px', position: 'insideStartTop', formatter: () => '(+75%)' }},
                    ]}
                },
                    
            ]
        };

        option && myChart.setOption(option);

    }

    useEffect(() => {
        // loadGraphBar01()
        // loadGraphPizza01()
        // loadGraphBar02()
        // loadGraphBar03()
    }, [blocks])

    const { t } = useTranslation();

    const menuList = [
        { label: ('Home'), value: 'home' },

    ];


    return (
        <Row>
            <Breadcrumb separator=">" items={routes} />
            <Col span={24} className="route-page">
            <PageHeader
                title={t('Program Operational Mng')}
                menu={[{ label: t('Home'), selected: true }]}
            />

                {/* <HeaderComponent>
                    <Row gutter={[4,4]} align={'middle'} style={{flexWrap: ''}}>
                        <Col span={24}>
                            <Row align={'middle'} justify={'space-between'}>
                                <Col> <Typography className="route-page-title">Program Operational Mng</Typography> </Col>
                            </Row>
                        </Col>
                    </Row>
                </HeaderComponent> */}
                <Row gutter={space} style={{marginTop: space[0]}} align={'top'}>
                    <Col span={24}>
                        <Row justify="space-between">
                            <Col md={3} sm={12}>
                                <KPIComponent title={t("Total Projects")} value={blocks?.totalPhases?.total} type='project' size='operational' />
                            </Col>
                            <Col md={3} sm={12}>
                                <KPIComponent title={t("Study")} value={blocks?.totalPhases?.estudo} type='project' size='operational' />
                            </Col>
                            <Col md={3} sm={12}>
                                <KPIComponent title={t("Planning")} value={blocks?.totalPhases?.planejamento} type='project' size='operational' />
                            </Col>
                            <Col md={3} sm={12}>
                                <KPIComponent title={t("Execution")} value={blocks?.totalPhases?.execucao} type='project' size='operational' />
                            </Col>
                            <Col md={3} sm={12}>
                                <KPIComponent title={t("Homologation")} value={blocks?.totalPhases?.homologacao} type='project' size='operational' />
                            </Col>
                            <Col md={3} sm={12}>
                                <KPIComponent title={t("Implementation")} value={blocks?.totalPhases?.implantacao} type='project' size='operational' />
                            </Col>
                            <Col md={3} sm={12}>
                                <KPIComponent title={t("Finished")} value={blocks?.totalPhases?.encerramento} type='project' size='operational' />
                            </Col>
                        </Row>
                    </Col>
                    {/* <Col span={18}>
                        <GraphComponent title={t("On-Going & Finished Projects By Program")}>
                            <div id="graphBar01" style={{height: 200}} />
                        </GraphComponent>
                    </Col>
                    <Col span={6}>
                        <GraphComponent title={t("On-Going & Finished Projects By Program")}>
                            <div id="graphPizza01" style={{height: 200}} />
                        </GraphComponent>
                    </Col>
                    <Col span={24}>
                        <GraphComponent title={t("End Date By Months")}>
                            <div id="graphBar02" style={{height: 200}} />
                        </GraphComponent>
                    </Col>
                    <Col span={24}>
                        <GraphComponent title={t("Process Time Based")}>
                            <div id="graphBar03" style={{height: 200}} />
                        </GraphComponent>
                    </Col> */}
                </Row>

            </Col>
        </Row>
    )

}

export default MainInitiativeOperational;