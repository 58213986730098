import { Button, Form, Input, Modal, message } from "antd";
import { useEffect, useState } from "react";
import './style.css';
import { recoverPassword } from "bto_now/api/seguranca/recoverPassword";


const ForgotPassword = ({ visible, onClose }) => {
    // const [formStep, setFormStep] = useState(1);
    // const [ ,setEmail] = useState("");
    const [formData, setFormData] = useState({
        email: "",
        password: "",
        confirmPassword: ""
    });
    const [isloading, setIsLoading] = useState(false);

    useEffect(() => {
        if (!visible) {
            // Se o modal estiver fechado, resete o passo do formulário e limpe os dados dos inputs
            // setFormStep(1);
            setFormData({
                email: "",
                password: "",
                confirmPassword: ""
            });
        }
    }, [visible]);

    const onFinish = async (values) => {
        try {
            setIsLoading(true);

            const { email } = values;
            const { data } = await recoverPassword({ email });
            if (data.sucesso) {
                message.success("E-mail para recuperação de senha enviado com sucesso.");
                onClose();
            } else {
                message.error("E-mail não corresponde a um usuário.");
                onClose();
            }
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false)
        }
    };

    return (
        <Modal
            className='forgot-title'
            title={<h2 style={{ fontSize: '26px', color: '#E10088', textAlign: 'center' }}>Esqueceu a senha?</h2>}
            open={visible}
            onCancel={onClose}
            footer={null}
            width={768}
        >
            <div className="forgot-modal">
                {/* {formStep === 1 ? ( */}
                    <>
                        <div className="forgot-subtitle">
                            <span>Insira seu e-mail para redefinir sua senha.</span>
                        </div>
                        <Form onFinish={onFinish} initialValues={formData}>
                            <Form.Item
                                className="forgot-form__item"
                                name="email"
                                rules={[
                                    {
                                        required: true,
                                        message: "Por favor, insira seu e-mail.",
                                    },
                                    {
                                        type: "email",
                                        message: "E-mail inválido.",
                                    },
                                ]}
                            >
                                <Input placeholder="E-mail" />
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" loading={isloading}>
                                    Enviar
                                </Button>
                            </Form.Item>
                        </Form>
                    </>
                {/* ) : ( */}
                    {/* <>
                        <div className="forgot-subtitle">
                            <span>Insira sua nova senha.</span>
                        </div>
                        <Form onFinish={onFinish} initialValues={formData}>
                            <Form.Item
                                className="forgot-form__item"
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        message: "Por favor, insira sua nova senha.",
                                    },
                                ]}
                            >
                                <Input.Password placeholder="Nova Senha" />
                            </Form.Item>
                            <Form.Item
                                className="forgot-form__item"
                                name="confirmPassword"
                                dependencies={["password"]}
                                rules={[
                                    {
                                        required: true,
                                        message: "Por favor, confirme sua nova senha.",
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue("password") === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(
                                                new Error("As senhas não correspondem.")
                                            );
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password placeholder="Confirmar Nova Senha" />
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" htmlType="submit">
                                    Enviar
                                </Button>
                            </Form.Item>
                        </Form>
                    </> */}
                {/* )} */}
            </div>
        </Modal>
    );
};

export default ForgotPassword;