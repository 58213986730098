import { Col, Modal, Progress, Row, Spin, Typography } from "antd";
import { useEffect, useState } from "react";
import { POST_API, getToken, getUser } from 'services';

import { IoIosLink } from "react-icons/io";
import { IoChevronDown, IoChevronForward, IoPencil } from "react-icons/io5";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import './styles.css';

// import dados from '../../pages/Admin/Projects.json';
import { InputSearch, Button } from "shared/components";
import { debounce } from 'utils';
import { currency } from "utils";

import * as XLSX from "xlsx";
import Semaphore from "shared/components/SemaphoreVariant";
import { ComponentHeader } from "shared/layouts";
import { formatDateGetDatabase } from "utils/dates/formatDateGetDatabase";

const StandardComponent = () => {
    const { t } = useTranslation();

    const [load, setLoad] = useState(true);
    const [blocks, setBlocks] = useState([]);
    const [select, setSelect] = useState(null);

    const [groupBy, setGroupBy] = useState('programme')

    const [search, setSearch] = useState('');

    const onSelect = (index) => {

        var items = select;

        if (typeof select?.[index] !== 'undefined') {
            items = { ...items, [index]: !items[index] }
        } else {
            items = { ...items, [index]: true };
        }

        setSelect(items)

    }

    const onload = () => {
        POST_API('/load.php', { token: getToken(), user: getUser(), period: 'Quarter', size: 50, groupBy, search }).then(rs => rs.json()).then(res => {
            if (res.return) {
                setBlocks(res.data)
            } else { Modal.warning({ title: 'Atenção', content: res.msg }) }
        }).catch(() => null).finally(() => setLoad(false))
    }

    function statusCoast(statusCoast) {
        switch (statusCoast) {
            case 1:
                return "UNDER BUDGET"
            case 2:
                return "AT RISK"
            case 3:
                return "OVER BUDGET"
            default:
                return "UNDER BUDGET"
        }
    }

    function statusTime(statusTime) {
        switch (statusTime) {
            case 1:
                return "ON TIME"
            case 2:
                return "AT RISK"
            case 3:
                return "DELAYED"
            default:
                return "ON TIME"
        }
    }

    const onDownload = () => {
        const headers = [
            t("Project Name"), t("Project ID"), t("Status Time"), t("Status Coast"), 
            t("Program Name"), t("Sponsor"), t("IT Business Partner"), t("Business Area"), 
            t("Start Date"), t("Go Live Date"), t("End Date"), t("Actual Phase"), 
            t("Operational Progress"), t("Total Requested"), t("Extra Budget"), t("Forecast"), 
            t("Consumed"), t("Available"), t("Total Investment"), t("Total Capex"), t("Total Opex"), 
            t("Financial Progress"), // t("Total Running Cost"),
        ];
    
        const data = blocks.flatMap(block => 
            block.items.map(item => [
                item.initiativeName,
                item.initiativeId,
                statusTime(item.statusTime),
                statusCoast(item.statusCoast),
                item.programme.trim("x000D_"),
                item.sponsor,
                item.itBusinessPatner,
                item.businessArea,
                item.startDate_format,
                item.goLiveDate_format,
                item.endDate_format,
                item.actualPhaseDescription,
                item.operationalProgress + "%",
                currency(item.totalRequested, { maximumFractionDigits: 2 }),
                currency(item.extraBudget, { maximumFractionDigits: 2 }), 
                currency(item.forecast, { maximumFractionDigits: 2 }),
                currency(item.consumed, { maximumFractionDigits: 2 }),
                currency(item.available, { maximumFractionDigits: 2 }),
                currency(item.totalInvestiment, { maximumFractionDigits: 2 }),
                currency(item.totalInvestimentCapex, { maximumFractionDigits: 2 }),
                currency(item.totalInvestimentOpex, { maximumFractionDigits: 2 }),
                item.financialProgress + "%",
                // currency(item.totalRunningCoast, { maximumFractionDigits: 2 }) 
            ])
        );
    
        const ws = XLSX.utils.aoa_to_sheet([headers, ...data]);

        // Definindo a largura das colunas
        const colWidths = [
            { wch: 20 }, { wch: 10 }, { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 20 },
            { wch: 25 }, { wch: 45 }, { wch: 15 }, { wch: 15 }, { wch: 15 }, { wch: 20 },
            { wch: 20 }, { wch: 15 }, { wch: 15 }, { wch: 10 }, { wch: 10 }, { wch: 10 },
            { wch: 20 }, { wch: 15 }, { wch: 15 }, { wch: 20 }, { wch: 20 },
        ];

        ws['!cols'] = colWidths;
        let [ date, hours ] = new Date().toISOString().split(/T/)
        hours = hours.replace(/(.[0-9]{3}Z)/, '')
        hours = hours.replaceAll(":", "-")

        const fileName = `project_portfolio_list_${date}_${hours}.xlsx`

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws);
        XLSX.writeFile(wb,fileName);
        
        // window.open('/services/download.php?token=' + getToken() + '&user=' + getUser())
    };
    
    useEffect(() => {
        debounce(onload, 500)();
    }, [groupBy, search])
 
console.log(blocks)

    return (
        <Row className="standard">
            <ComponentHeader
                title={t("List Overview")}
                topRight={
                    <Row>
                        {/*<Link to={`/dashboard/mng-project/new/general`}>
                            <Button style={{ marginRight: 12, height: '100%' }} >
                                {t("Create Project")}
                            </Button>
                        </Link>*/}
                        <Button style={{ marginTop: 12, marginRight: 12 }} onClick={onDownload}>{t("Download")}</Button>
                        <InputSearch onChange={ev => setSearch(ev.target.value)} value={search} placeholder={t("Search")} />
                    </Row>
                }
                menuList={[
                    { label: t('Program'), value: 'p', onClick: () => { setGroupBy('programme') } },
                    { label: t('Business Drivers'), value: 'b', onClick: () => { setGroupBy('businessDrivers') } }
                ]}
            />
            {load ? <Col span={24} className="roadmap-load"><center><Spin /></center></Col> : (
                <>
                    {blocks.map((v, i) => (
                        <>
                            {select?.[i] ? (
                                <>
                                    <Col span={24} key={i} className="standard-col select" style={{ borderLeftColor: v.color, backgroundColor: `${v.color}1a` }} onClick={() => onSelect(i)}>
                                        <Row className="standard-row">
                                            <Col span={24} className="standard-col-col first">
                                                <IoChevronDown style={{ color: v.color }} className="standard-col-col-arrow" />
                                                <Typography className="standard-title" style={{ color: v.color }}>{v.name}</Typography>
                                            </Col>
                                        </Row>
                                    </Col>
                                    {select?.[i] ? (
                                        <Col span={24} className="standard-col item" style={{ borderLeftColor: v.color, backgroundColor: `${v.color}1a` }} >
                                            <Row className="standard-row">
                                                <Col flex={'40px'} className="standard-col-subcol"></Col>
                                                <Col flex={'41px'} className="standard-col-subcol"></Col>
                                                <Col flex={'400px'} className="standard-col-subcol">
                                                    <Typography className="standard-data">{t('Project Name')}</Typography>
                                                </Col>
                                                <Col flex={'150px'} className="standard-col-subcol">
                                                    <Typography className="standard-data">{t('Project ID')}</Typography>
                                                </Col>
                                                <Col flex={'100px'} className="standard-col-subcol">
                                                    <Typography className="standard-data">{t('Status Time')}</Typography>
                                                </Col>
                                                <Col flex={'100px'} className="standard-col-subcol">
                                                    <Typography className="standard-data">{t('Status Cost')}</Typography>
                                                </Col>
                                                <Col flex={'250px'} className="standard-col-subcol">
                                                    <Typography className="standard-data">{t('Program Name')}</Typography>
                                                </Col>
                                                <Col flex={'180px'} className="standard-col-subcol">
                                                    <Typography className="standard-data">{t('Sponsor')}</Typography>
                                                </Col>
                                                <Col flex={'180px'} className="standard-col-subcol">
                                                    <Typography className="standard-data">{t('IT Business Partner')}</Typography>
                                                </Col>
                                                <Col flex={'180px'} className="standard-col-subcol">
                                                    <Typography className="standard-data">{t('Business Area')}</Typography>
                                                </Col>
                                                <Col flex={'130px'} className="standard-col-subcol">
                                                    <Typography className="standard-data">{t('Start Date')}</Typography>
                                                </Col>
                                                <Col flex={'130px'} className="standard-col-subcol">
                                                    <Typography className="standard-data">{t('Go-Live Date')}</Typography>
                                                </Col>
                                                <Col flex={'130px'} className="standard-col-subcol">
                                                    <Typography className="standard-data">{t('End Date')}</Typography>
                                                </Col>
                                                <Col flex={'180px'} className="standard-col-subcol">
                                                    <Typography className="standard-data">{t('Actual Phase')}</Typography>
                                                </Col>
                                                <Col flex={'180px'} className="standard-col-subcol">
                                                    <Typography className="standard-data">{t('Operational Progress (%)')}</Typography>
                                                </Col>
                                                <Col flex={'180px'} className="standard-col-subcol">
                                                    <Typography className="standard-data">{t('Total Budget ($)')}</Typography>
                                                </Col>
                                                <Col flex={'180px'} className="standard-col-subcol">
                                                    <Typography className="standard-data">{t('Extra Budget ($)')}</Typography>
                                                </Col>
                                                <Col flex={'180px'} className="standard-col-subcol">
                                                    <Typography className="standard-data">{t('Forecast ($)')}</Typography>
                                                </Col>
                                                <Col flex={'180px'} className="standard-col-subcol">
                                                    <Typography className="standard-data">{t('Consumed ($)')}</Typography>
                                                </Col>
                                                <Col flex={'180px'} className="standard-col-subcol">
                                                    <Typography className="standard-data">{t('Available ($)')}</Typography>
                                                </Col>
                                                <Col flex={'180px'} className="standard-col-subcol">
                                                    <Typography className="standard-data">{t('Total Investment ($)')}</Typography>
                                                </Col>
                                                <Col flex={'180px'} className="standard-col-subcol">
                                                    <Typography className="standard-data">{t('Total Capex Real ($)')}</Typography>
                                                </Col>
                                                <Col flex={'180px'} className="standard-col-subcol">
                                                    <Typography className="standard-data">{t('Total Opex Real ($)')}</Typography>
                                                </Col>
                                                <Col flex={'180px'} className="standard-col-subcol">
                                                    <Typography className="standard-data">{t('Financial Progress (%)')}</Typography>
                                                </Col>
                                                {/* <Col flex={'180px'} className="standard-col-subcol">
                                                    <Typography className="standard-data">{t('Total Running Cost ($)')}</Typography>
                                                </Col> */}
                                            </Row>
                                            {v.items.map((v1, i1) => (
                                                <Row className="standard-row" style={{ width: '4130px' }}>
                                                    <Col flex={'40px'} className="standard-col-subcol">
                                                        <Link to={`/dashboard/initiative/${v.id}/${v1.initiativeId}`}><Typography style={{ width: '20px' }} className="standard-data item center"><IoIosLink className="standard-data-action" size={16} /></Typography></Link>
                                                    </Col>
                                                    <Col flex={'40px'} className="standard-col-subcol">
                                                        <Link to={`/dashboard/mng-project/${v1.initiativeId}/general`}>
                                                            <Typography style={{ width: '20px' }} className="standard-data item center">
                                                                <IoPencil className="standard-data-action" size={16} />
                                                            </Typography>
                                                        </Link>
                                                    </Col>
                                                    <Col flex={'400px'} className="standard-col-subcol">
                                                        <Typography className="standard-data item" style={{ width: '379px' }}>{t(v1.initiativeName)}</Typography>
                                                    </Col>
                                                    <Col flex={'150px'} className="standard-col-subcol">
                                                        <Typography className="standard-data item center" style={{ width: '129px' }}>{v1.initiativeId}</Typography>
                                                    </Col>
                                                    <Col flex={'100px'} style={{alignItems: 'center'}} className="standard-col-subcol">
                                                        {/* <Typography className="standard-data item center">{STATUS[v1.statusTime]}</Typography> */}
                                                        <Semaphore value={v1.statusTime} />
                                                    </Col>
                                                    <Col flex={'100px'} style={{alignItems: 'center'}} className="standard-col-subcol">
                                                        {/* <Typography className="standard-data item center">{STATUS[v1.statusCoast]}</Typography> */}
                                                        <Semaphore value={v1.statusCoast} />
                                                    </Col>
                                                    <Col flex={'250px'} className="standard-col-subcol">
                                                        <Typography className="standard-data item" style={{ width: '229px' }}>{t(v1.programme)}</Typography>
                                                    </Col>
                                                    <Col flex={'180px'} className="standard-col-subcol">
                                                        <Typography className="standard-data item" style={{ width: '159px' }}>{t(v1.sponsor)}</Typography>
                                                    </Col>
                                                    <Col flex={'180px'} className="standard-col-subcol">
                                                        <Typography className="standard-data item" style={{ width: '159px' }}>{t(v1.itBusinessPatner)}</Typography>
                                                    </Col>
                                                    <Col flex={'180px'} className="standard-col-subcol">
                                                        <Typography className="standard-data item" style={{ width: '159px' }}>{t(v1.businessArea)}</Typography>
                                                    </Col>
                                                    <Col flex={'130px'} className="standard-col-subcol">
                                                        <Typography className="standard-data item center" style={{ width: '109px' }}>{formatDateGetDatabase(v1.startDate)}</Typography>
                                                    </Col>
                                                    <Col flex={'130px'} className="standard-col-subcol">
                                                        <Typography className="standard-data item center" style={{ width: '109px' }}>{formatDateGetDatabase(v1.goLiveDate)}</Typography>
                                                    </Col>
                                                    <Col flex={'130px'} className="standard-col-subcol">
                                                        <Typography className="standard-data item center" style={{ width: '109px' }}>{formatDateGetDatabase(v1.endDate)}</Typography>
                                                    </Col>
                                                    <Col flex={'180px'} className="standard-col-subcol">
                                                        <Typography className="standard-data item">{t(v1.actualPhaseDescription)}</Typography>
                                                    </Col>
                                                    <Col flex={'180px'} className="standard-col-subcol bar">
                                                        <Progress size={['100%', '13px']} percent={t(v1.operationalProgress)} showInfo={false} strokeColor={`${v.color}3a`} />
                                                        <Typography className="standard-data item center absolute">{v1.operationalProgress}</Typography>
                                                    </Col>
                                                    <Col flex={'180px'} className="standard-col-subcol">
                                                        <Typography className="standard-data item money"><span>$</span> {v1.totalRequested ? (v1.totalRequested).toLocaleString('pt-BR') : 0}</Typography>
                                                    </Col>
                                                    <Col flex={'180px'} className="standard-col-subcol">
                                                        <Typography className="standard-data item money"><span>$</span> {v1.extraBudget ? (v1.extraBudget).toLocaleString('pt-BR') : 0}</Typography>
                                                    </Col>
                                                    <Col flex={'180px'} className="standard-col-subcol">
                                                        <Typography className="standard-data item money"><span>$</span> {v1.forecast ? (v1.forecast).toLocaleString('pt-BR') : 0}</Typography>
                                                    </Col>
                                                    <Col flex={'180px'} className="standard-col-subcol">
                                                        <Typography className="standard-data item money"><span>$</span> {v1.consumed ? (v1.consumed).toLocaleString('pt-BR') : 0}</Typography>
                                                    </Col>
                                                    <Col flex={'180px'} className="standard-col-subcol">
                                                        <Typography className="standard-data item money"><span>$</span> {v1.available ? (v1.available).toLocaleString('pt-BR') : 0}</Typography>
                                                    </Col>
                                                    <Col flex={'180px'} className="standard-col-subcol">
                                                        <Typography className="standard-data item money"><span>$</span> {v1.totalInvestiment ? (v1.totalInvestiment).toLocaleString('pt-BR') : 0}</Typography>
                                                    </Col>
                                                    <Col flex={'180px'} className="standard-col-subcol">
                                                        <Typography className="standard-data item money"><span>$</span> {v1.totalInvestimentCapex ? (v1.totalInvestimentCapex).toLocaleString('pt-BR') : 0}</Typography>
                                                    </Col>
                                                    <Col flex={'180px'} className="standard-col-subcol">
                                                        <Typography className="standard-data item money"><span>$</span> {v1.totalInvestimentOpex ? (v1.totalInvestimentOpex).toLocaleString('pt-BR') : 0}</Typography>
                                                    </Col>
                                                    <Col flex={'320px'} className="standard-col-subcol bar" style={{ position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                        <Progress className="standard-data item center" size={['100%', '13px']} percent={v1.financialProgress} showInfo={false} strokeColor={`${v.color}3a`} style={{ width: '100%' }} />
                                                        <Typography className="standard-data item center" style={{ position: 'absolute', textAlign: 'center' }}>{v1.financialProgress} %</Typography>
                                                    </Col>
                                                    {/* <Col flex={'180px'} className="standard-col-subcol">
                                                        <Typography className="standard-data item money"><span>$ {(v1.totalRunningCoast).toLocaleString('pt-BR')}</span> </Typography>
                                                    </Col> */}
                                                </Row>
                                            ))}
                                        </Col>
                                    ) : null}
                                </>
                            ) : (
                                <>
                                    <Col span={24} key={i} className="standard-col" style={{ borderLeftColor: v.color, backgroundColor: `${v.color}1a` }} onClick={() => onSelect(i)}>
                                        <Row className="standard-row">
                                            <Col span={16} className="standard-col-col first">
                                                <div style={{ display: 'flex' }}>
                                                    <IoChevronForward style={{ color: v.color }} className="standard-col-col-arrow" />
                                                    <div>
                                                        <Typography className="standard-title" style={{ color: v.color }}>{v.name}</Typography>
                                                        <Typography className="standard-subtitle">{v.items.length} {t("Initiatives")}</Typography>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col span={2} className="standard-col-col">
                                                <center><Link to={`/dashboard/initiative/${v.id}`}><Typography style={{ width: '20px' }} className="standard-data-title item center"><IoIosLink className="standard-data-title-action" size={22} /></Typography></Link></center>
                                            </Col>
                                            <Col span={3} className="standard-col-col">
                                                <Typography className="standard-data">Budget Plan<br /><span>{currency(v.budgetPlan)}</span></Typography>
                                            </Col>
                                            <Col span={3} className="standard-col-col">
                                                <Typography className="standard-data">Total Forecast<br /><span>{currency(v.totalForecast)}</span></Typography>
                                            </Col>
                                            <Col span={3} className="standard-col-col">
                                                <Typography className="standard-data">{t("Budget Real")}<br /><span>{currency(v.budgetReal)}</span></Typography>
                                            </Col>
                                            <Col span={3} className="standard-col-col">
                                                <Typography className="standard-data">{t("Start Date")}<br /><span>{v.startDate_format}</span></Typography>
                                            </Col>
                                            <Col span={3} className="standard-col-col">
                                                <Typography className="standard-data">{t("End Date")}<br /><span>{v.endDate_format}</span></Typography>
                                            </Col>
                                        </Row>
                                    </Col>
                                </>
                            )}
                        </>
                    ))}
                </>
            )}
        </Row>
    )
}

export default StandardComponent;
