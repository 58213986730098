import styles from "./styles.module.css";
import { getListScopeStatus } from 'bto_now/api/scopeStatus/getListScopeStatus';
import { getListScopeType } from 'bto_now/api/scopeType/getListScopeType';
import React, { useState, useEffect } from 'react';
import { Card, Spinner } from 'react-bootstrap';
import { useTable } from 'react-table';
import { getUser } from 'services';
import { useStatusReportStore } from 'shared/store-zustand/get-status-report/getStatusReport';
import { convertStatusIdInStatus } from 'utils/convertStatus/convertStatusIdInStatus';
import { convertTypeIdInType } from 'utils/convertType/convertTypeIdInType';
import { useParams } from 'react-router-dom';
import { useTypeStatusReportStore } from 'shared/store-zustand/get-status-report/getTypeStatusReport.ts';
import { useTranslation } from "react-i18next";

const Scope = () => {  
  const [loading, setLoading] = useState(true);
  const [scopeTypeList, setScopeTypeList] = useState([]);
  const [scopeStatusList, setScopeStatusList] = useState([]);
  const [dataApi, setData] = useState([]);

  const { id } = useParams();
  const { getStatusReport } = useStatusReportStore();
  const { typeStatusReport } = useTypeStatusReportStore();
  const { t } = useTranslation();
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: scopeTypeData } = await getListScopeType({ User: getUser() });
        setScopeTypeList(scopeTypeData);
        const { data: scopeStatusData } = await getListScopeStatus({ User: getUser() });
        setScopeStatusList(scopeStatusData);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false); 
      };
    };
    
    fetchData();
  }, []);

  useEffect(() => {
    getStatusReport(Number(id), typeStatusReport)
    .then((data) => {
      if (data) {
        setData(data.scopeManagementList);
      }
      setLoading(false);
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
      setLoading(false);
    });
}, []);


  const columns = React.useMemo(() => [
    // {
    //   Header: t('Name'),
    //   accessor: 'name'
    // },
    {
      Header: t('Description'),
      accessor: 'description',
      Cell: ({ value }) => (
        <div
          title={value}
          style={{
            display: '-webkit-box',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'normal',
          }}
        >
          {value}
        </div>
      ),
    },
    // {
    //   Header: t('Type'),
    //   accessor: 'scopeTypeId',
    //   Cell: ({ value }) => (
    //     <span>
    //       {t(convertTypeIdInType(scopeTypeList, value))}
    //     </span>
    //   )
    // },
    {
      Header: t('Status'),
      accessor: 'scopeStatusId',
      Cell: ({ value }) => (
        <span>
          {t(convertStatusIdInStatus(scopeStatusList, value))}
        </span>
      )
    }
  ], [scopeStatusList]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data: dataApi });

  if (loading) {
    return (
      <Card style={{ height: '350px' }}> 
        <Card.Body className="text-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">{t('Loading...')}</span>
          </Spinner>
        </Card.Body>
      </Card>
    );
  }

  return (
    <Card className={`${styles.card} ${styles.printCard}`}>
      <Card.Header>
        <h3 style={{ marginTop: '0.5rem', marginBottom: '0', fontSize: '16px' }}>
          {t('Scope')}
        </h3>
      </Card.Header>
      <Card.Body className="p-0" style={{ overflowY: 'auto', paddingBottom: '0' }}>
        <table {...getTableProps()} className="table table-striped">
          <thead style={{ fontSize: '13px' }}>
            {headerGroups.map(headerGroup => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => {
                  const { key, ...remainingProps } = column.getHeaderProps(); 
                  return (
                    <th key={key} style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} {...remainingProps}>{column.render('Header')}</th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()} style={{ fontSize: '13px' }}>
            {rows.map(row => {
              prepareRow(row);
              return (
                <tr key={row.original.scopeId} {...row.getRowProps()}>
                  {row.cells.map(cell => (
                    <td 
                      key={cell.column.id} 
                      title={cell.value}
                      style={{ 
                        whiteSpace: 'nowrap', 
                      }} 
                      className={cell.getCellProps().className}
                    >
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </Card.Body>
    </Card>
  );
};

export default Scope;
