import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Login from "./pages/Auth/Login";
import TopHeader from "shared/layouts/TopHeader";
import Initiative from "./pages/Admin/HomeBTO-php/initiative/Initiative";
import Finance from "./pages/Admin/HomeBTO-php/Finance";
import Operational from "./pages/Admin/HomeBTO-php/Operational";
import MainInitiaive from "./pages/Admin/HomeBTO-php/initiative/MainInitiaive";
import Homepage from "./pages/Admin/HomeBTO-php/InitiativeTabs/Homepage";
import Scope from "./pages/Admin/HomeBTO-php/InitiativeTabs/Scope";
import Financial from "./pages/Admin/HomeBTO-php/InitiativeTabs/Financial";
import Resourses from "./pages/Admin/HomeBTO-php/InitiativeTabs/Resourses";
import RII from "./pages/Admin/HomeBTO-php/InitiativeTabs/RII";
import OperationalTab from "./pages/Admin/HomeBTO-php/InitiativeTabs/Operational";
import MainInitiativeFinancial from "./pages/Admin/HomeBTO-php/initiative/MainInitiativeFinancial";
import MainInitiativeOperational from "./pages/Admin/HomeBTO-php/initiative/MainInitiativeOperational";

import Business from "./pages/Admin/HomeBTO-php/Business/index";

import BusinessCanva from "./pages/Admin/HomeBTO-php/BusinessCanva";

import RitualsMng from "bto_now/pages/Admin/HomeBTO-php/RitualsMng/Main";
import RitualsMngHeader from "bto_now/pages/Admin/HomeBTO-php/RitualsMng/RitualMngHeader";
import RitualsMngOperational from "bto_now/pages/Admin/HomeBTO-php/RitualsMng/Operational";
import RitualsMngFinancial from "bto_now/pages/Admin/HomeBTO-php/RitualsMng/Financial";
import RitualsMngIssues from "bto_now/pages/Admin/HomeBTO-php/RitualsMng/Issues";
import RitualsMngRisks from "bto_now/pages/Admin/HomeBTO-php/RitualsMng/Risks";
import RitualsMngTaskMng from "bto_now/pages/Admin/HomeBTO-php/RitualsMng/TaskMng";

import Profile from "./pages/Admin/HomeBTO-php/Profile";

import CreateProject from "bto_now/pages/Admin/HomeBTO-php/Project"
import GeneralProject from "bto_now/pages/Admin/HomeBTO-php/Project/components/GeneralProject";
import FinancialProject from "bto_now/pages/Admin/HomeBTO-php/Project/components/FinancialProject";
import OperationalProject from "bto_now/pages/Admin/HomeBTO-php/Project/components/OperationalProject";
// import { ThemeProvider } from "styled-components";
// import { lightTheme } from "themes/lightTheme";
import HomeBTO from "bto_now/pages/Admin/HomeBTO";
import TMO from "bto_now/pages/Admin/HomeBTO/TMO";
import BTO_TMO_PlatFormMng_List from "bto_now/pages/Admin/HomeBTO/TMO/PlatformManagment";
import TMO_MP_List_Detail from "bto_now/pages/Admin/HomeBTO/TMO/PlatformManagment/Detail";
import TMO_LandscapeIntegration from "bto_now/pages/Admin/HomeBTO/TMO/LandscapeIntegration";
import BMO from "bto_now/pages/Admin/HomeBTO/BMO";
import BTO_BMO_PlatFormMng_List from "bto_now/pages/Admin/HomeBTO/BMO/ProcessManagement";
import BMO_LandscapeIntegration from "bto_now/pages/Admin/HomeBTO/BMO/ValueStreamMap";
import TimelineMng from "./pages/Admin/HomeBTO-php/Project/components/TimelineMng";

//PMO --> Administration Project Management
import DigitalStatusReport from "./pages/Admin/HomeBTO/PMO/DigitalStatusReport";
import PMO from "./pages/Admin/HomeBTO/PMO";
import PMO_ProjectScopeMng from "./pages/Admin/HomeBTO/PMO/ProjectScopeMng";
import PMO_Scope from "./pages/Admin/HomeBTO/PMO/ProjectScopeMng/Scope";
import PMO_Task from "./pages/Admin/HomeBTO/PMO/ProjectScopeMng/Task";
// import PMO_RitualProject from "./pages/Admin/HomeBTO/PMO/RitualProject";
import PMO_NewCreateProject from "./pages/Admin/HomeBTO/PMO/NewCreateProject";
import PMO_NewRitualProject from "./pages/Admin/HomeBTO/PMO/RitualProject";
import ModalInactivity from "shared/components/ModalInactivity";
import { useCallback, useEffect, useState } from "react";
import PMO_Create_FinancialProject from "./pages/Admin/HomeBTO/PMO/NewCreateProject/FinancialProject";
import PMO_Create_GeneralProject from "./pages/Admin/HomeBTO/PMO/NewCreateProject/GeneralProject";
import PMO_Create_Timeline from "./pages/Admin/HomeBTO/PMO/NewCreateProject/TimelineProject";
import PMO_Create_Scope from "./pages/Admin/HomeBTO/PMO/NewCreateProject/ScopeProject";
import PMO_Ritual_Timeline from "./pages/Admin/HomeBTO/PMO/RitualProject/TimelineRitual";
import PMO_Ritual_Scope from "./pages/Admin/HomeBTO/PMO/RitualProject/ScopeRitual";
import PMO_Ritual_Risks from "./pages/Admin/HomeBTO/PMO/RitualProject/RisksRitual";
import PMO_Ritual_Issues from "./pages/Admin/HomeBTO/PMO/RitualProject/IssuesRitual";
import PMO_Ritual_Financial from "./pages/Admin/HomeBTO/PMO/RitualProject/FinancialRitual";
import Help from "./pages/Support/Help";
import Request from "./pages/Support/Request";
import ProgramByList from "./pages/Admin/HomeBTO/PortfolioMngOffice/PortfolioExecution/ProgramByList";
import TotalInvestments from "./pages/Admin/HomeBTO-php/FinanceTabs/TotalInvestments";
import TotalInvestmentsCapex from "./pages/Admin/HomeBTO-php/FinanceTabs/TotalInvestmentsCapex";
import TotalInvestmentsOpex from "./pages/Admin/HomeBTO-php/FinanceTabs/TotalInvestmentsOpex";

// Componentes substitutos.
import TotalInvestmentsTest from "./pages/Admin/HomeBTO-php/FinanceTabs/TotalIvestments";
import TotalInvestmentsCapexTest from "./pages/Admin/HomeBTO-php/FinanceTabs/TotalInvestmentsCapex/index";
import TotalInvestmentsOpexTest from "./pages/Admin/HomeBTO-php/FinanceTabs/TotalInvestimentsOpex";
import PortfolioMngOffice from "./pages/Admin/HomeBTO/PortfolioMngOffice";
import ProgramsByGantt from "./pages/Admin/HomeBTO/PortfolioMngOffice/PortfolioExecution/Gantt";
import ProjectDetails from "./pages/Admin/HomeBTO/PortfolioMngOffice/PortfolioExecution/ProjectDetails";
import PortfolioOperational from "./pages/Admin/HomeBTO/PortfolioMngOffice/PortfolioExecution/PortfolioOperational/index";
import PrivateRoute from "./pages/Auth/components/IsAuth";
import { logoutService } from "services";
import KanbanPage from "./pages/Admin/HomeBTO/PortfolioMngOffice/PortfolioExecution/Kanban";
import Portfolio from "./pages/Admin/HomeBTO-php/Portifolio";
import StandardComponent from "./pages/Admin/HomeBTO-php/Portifolio/layouts/Standard";
import RoadmapComponent from "./pages/Admin/HomeBTO-php/Portifolio/layouts/Roadmap";
import NewPassword from "./pages/Auth/newPassword/NewPassword";
import IsAuthByToken from "shared/middlawares/isAuthByToken";

const App = () => {

  const navigate = useNavigate()
  const location = useLocation();
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    setIsAuthenticated(location.pathname !== '/');
  }, [location])

  const logout = useCallback(() => {
    setIsAuthenticated(false)
    logoutService()
  }, [navigate])

  return (
    // <ThemeProvider theme={lightTheme}>
    <>
      {isAuthenticated && <ModalInactivity logout={logout} />}
      <Routes>
        <Route path="" element={<Login />} />
        <Route path="/new-password" element={<IsAuthByToken element={NewPassword} />} />

        <Route path="dashboard" element={ <PrivateRoute> <TopHeader /> </PrivateRoute>}>
          <Route path="" element={<Portfolio />}>
            <Route path="" element={<RoadmapComponent />} />

            <Route path="list" element={<StandardComponent />} />
          </Route>

          <Route path="profile" element={<Profile />} />
          <Route path="help" element={<Help />} />
          <Route path="help/request" element={<Request />} />

          <Route path="mng-project/:projectId" element={<CreateProject />}>
            <Route path='general' element={<GeneralProject />} />
            <Route path="operational" element={<OperationalProject />} />
            <Route path="financial" element={<FinancialProject />} />
            <Route path="timelineMng" element={<TimelineMng />} />
          </Route>

          <Route path="financial" element={<Finance />}>
            <Route path="" element={<TotalInvestments />} />
            <Route path="capex" element={<TotalInvestmentsCapex />} />
            <Route path="opex" element={<TotalInvestmentsOpex />} />
          </Route>
          
          {/*Rota temporária de testes, feita para substituir a de financial quando estiver finalizada.*/}
          <Route path="financial/graph" element={<Finance />}>
            <Route path="" element={<TotalInvestmentsTest />} />
            <Route path="capex" element={<TotalInvestmentsCapexTest />} />
            <Route path="opex" element={<TotalInvestmentsOpexTest />} />
          </Route>

          {/* <Route path="operational" element={<Operational />} /> */}
          <Route path="operational" element={<PortfolioOperational />} />
          

          <Route path="business/:BUSINESS" element={<Business />} />
          <Route path="business/:BUSINESS/canva" element={<BusinessCanva />} />
          <Route path="business/:BUSINESS/kanban" element={<KanbanPage />} />

          <Route path="initiative/:MAIN" element={<MainInitiaive />} />
          <Route path="initiative/:MAIN/financial" element={<MainInitiativeFinancial />} />
          <Route path="initiative/:MAIN/operational" element={<MainInitiativeOperational />} />

          <Route path="initiative/:MAIN/:INITIATIVE" element={<Initiative />} >
            <Route path="" element={<Homepage />} />
            <Route path="ritual-mng" element={<RitualsMng />} />
            <Route path="scope" element={<Scope />} />
            <Route path="operational" element={<OperationalTab />} />
            <Route path="financial" element={<Financial />} />
            <Route path="resources" element={<Resourses />} />
            <Route path="rii" element={<RII />} />
          </Route>

          {/**
             * MAIN = Programme ID
             * INITIATIVE = Project ID
             */}
          <Route path="initiative/:programmeId/:projectId/ritual-mng" element={<RitualsMng />} />
          <Route path="initiative/:programmeId/:projectId/ritual/:ritualNumber" element={<RitualsMngHeader />} >
            <Route path="operational" element={<RitualsMngOperational />} />
            <Route path="financial" element={<RitualsMngFinancial />} />
            <Route path="risks" element={<RitualsMngRisks />} />
            <Route path="issues" element={<RitualsMngIssues />} />
            <Route path="taskMng" element={<RitualsMngTaskMng />} />
          </Route>

          <Route path="initiative/new" element={<ProjectDetails />} />

          <Route path="mng" element={<HomeBTO />} />

          <Route path="mng/portfolio" element={<PortfolioMngOffice />} >
            <Route path="gantt" element={<ProgramsByGantt />} />
            <Route path="list" element={<ProgramByList />} >
            </Route>
          </Route>
          <Route path="mng/pmo" element={<PMO />}>
            <Route path="digital-status-report" element={<PMO_ProjectScopeMng />} />
            <Route path="digital-status-report/dashboard/:id" element={<DigitalStatusReport />} />
            <Route path="project-scope-mng" element={<PMO_ProjectScopeMng />} />
            <Route path="project-scope-mng/scope/:id" element={<PMO_Scope />} />
            <Route path="project-tasks-mng" element={<PMO_ProjectScopeMng />} />
            <Route path="project-tasks-mng/task/:id" element={<PMO_Task />} />
            <Route path="project-ritual-mng" element={<PMO_ProjectScopeMng />} />
            <Route path="project-ritual-mng/ritual/:id" element={<PMO_NewRitualProject />}>
              <Route path="scope" element={<PMO_Ritual_Scope />} />
              <Route path="timeline" element={<PMO_Ritual_Timeline />} />
              <Route path="risks" element={<PMO_Ritual_Risks />} />
              <Route path="issues" element={<PMO_Ritual_Issues />} />
              <Route path="financial" element={<PMO_Ritual_Financial />} />
            </Route>
            {/* <Route path="project-ritual-mng/ritual/:id" element={} /> */}
            <Route path="new-create-project" element={<PMO_NewCreateProject />}>
              <Route path='general' element={<PMO_Create_GeneralProject />} />
              <Route path="operational" element={<PMO_Create_Timeline />} />
              <Route path="financial" element={<PMO_Create_FinancialProject />} />
              <Route path="scope" element={<PMO_Create_Scope />} />
            </Route>
          </Route>
          <Route path="mng/bmo" element={<BMO />}>
            <Route path="platform-mng/list" element={<BTO_BMO_PlatFormMng_List />} />
            {/* <Route path="platform-mng/list/detail/:id" element={<BD_BMO_MP_List_Detail/>}/> */}
            <Route path="landscape-integration" element={<BMO_LandscapeIntegration />} />
          </Route>
          <Route path="mng/tmo" element={<TMO />}>
            <Route path="platform-mng/list" element={<BTO_TMO_PlatFormMng_List />} />
            <Route path="platform-mng/list/detail/:id" element={<TMO_MP_List_Detail />} />
            <Route path="landscape-integration" element={<TMO_LandscapeIntegration />} />
          </Route>
        </Route>
      </Routes>
    </>
    // </ThemeProvider>
  )
}

export default App;