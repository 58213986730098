import styled from "styled-components";

export const TitleCard = styled.div`
  padding-bottom: 8px;
  size: 24px;
`;

export const TitleCardWithBorder = styled.div`
  padding-bottom: 8px;
  size: 24px;
  border-bottom: 1px solid;
`;

export const Card = styled.div`
  padding: ${(p) => p.theme.spacing.sm};
  background: white;
  border-radius: ${(p) => p.theme.border.radius.sm};
  max-width: 30%;
  min-width: 18%;
  min-height: 50%;
  margin: 1% 1% 0 0;
  box-shadow: ${(p) => p.theme.colors.blackBoxShadow};
`;

export const CardTracking = styled.div`
  padding-top: ${(p) => p.theme.spacing.sm};
  padding-right: ${(p) => p.theme.spacing.sm};
  padding-left: ${(p) => p.theme.spacing.sm};
  padding-bottom: ${(p) => p.theme.spacing.xs};
  background: white;
  border-radius: ${(p) => p.theme.border.radius.sm};
  max-width: 30%;
  min-width: 18%;
  min-height: 50%;
  margin: 1% 1% 0 0;
  box-shadow: ${(p) => p.theme.colors.blackBoxShadow};
`;

export const CardCurveS = styled.div`
  border-radius: ${(p) => p.theme.border.radius.sm};
  max-width: 50%;
  min-width: 40%;
  margin: 1% 1% 0px 0px;
  width: 40%;
  min-height: 50%;
  box-shadow: ${(p) => p.theme.colors.blackBoxShadow};
  overflow-x: auto;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
`;
