import { AxiosResponse } from 'axios';
import { btoApi } from '../client';

export interface UpdatePasswordRequest {
  token: string | null;
  password: string;
}

export interface UpdatePasswordResponse {
  message: string;
  status: number;
  sucesso: boolean
}

export function updatePassword (params: UpdatePasswordRequest): Promise<AxiosResponse<UpdatePasswordResponse>> {
  return btoApi.post(`api/Seguranca/AtualizarSenha?token=${params.token}&senhaNova=${params.password}`);
}