import React, { useState, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

interface IsValidTokenProps {
  element: React.ComponentType;  
}

const IsValidToken = ({ element: Component }: IsValidTokenProps) => {
  const query = new URLSearchParams(useLocation().search);
  
  const isAuthenticated = () => {
    const token = query.get('token')
    return !!token; 
  };

  return isAuthenticated() ? <Component /> : <Navigate to="/" replace />;
};

export default IsValidToken;