import { AxiosResponse } from 'axios';
import { btoApi } from '../client';

export interface RecoverPasswordRequest {
  email: string;
}

export interface RecoverPasswordResponse {
  message: string;
  status: number;
  sucesso: boolean
}

export function recoverPassword (params: RecoverPasswordRequest): Promise<AxiosResponse<RecoverPasswordResponse>> {
  return btoApi.post(`/api/Seguranca/RecuperarSenha?email=${params.email}`);
}