import React, { useEffect, useState } from 'react';
import { Card, Spinner } from 'react-bootstrap';
import { useStatusReportStore } from 'shared/store-zustand/get-status-report/getStatusReport';
import { useParams } from 'react-router-dom';
import { Semaphore } from './Semaphore';
import { useTypeStatusReportStore } from 'shared/store-zustand/get-status-report/getTypeStatusReport.ts';
import { useTranslation } from "react-i18next";
import FalconComponentCard from 'shared/theme/components/FalconComponentCard';
import FalconCardHeader from 'shared/theme/components/FalconCardHeader';

const Alerts = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [dataApi, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { getStatusReport} = useStatusReportStore();
  const {typeStatusReport} = useTypeStatusReportStore();

  useEffect(() => {
    getStatusReport(Number(id), typeStatusReport)
    .then((data) => {
      if (data) {
        setData(data.projectInformation);
      }
      setLoading(false);
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
      setLoading(false);
    });
}, []);

  if (!dataApi & loading) {
    return (
      <Card style={{ height: '350px' }}> 
        <Card.Body className="text-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">{t('Loading...')}</span>
          </Spinner>
        </Card.Body>
      </Card>
    );
  }

  return (
    <FalconComponentCard style={{ width: '100%' }}>
      <FalconCardHeader title={<span style={{ fontSize: '16px',whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{t('Project Information')}</span>} titleTag="h5" />
      <Card.Body style={{ padding: '19px 20px' }}>
        <Semaphore data={dataApi} />
      </Card.Body>
    </FalconComponentCard>
  );
};

export default Alerts;

