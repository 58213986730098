import {
  Button,
  Col,
  Form,
  Image,
  Input,
  message,
  Row,
  Typography,
} from "antd";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// STYLES
import "./style";

// IMAGENS
import logo from "shared/assets/img/BTONow_logo-sem_assinatura_Terciaria-Negativo.png";
  
// ICONES
import {
  IoLockClosedOutline,
  IoLogoFacebook,
  IoLogoInstagram,
  IoLogoTwitter,
} from "react-icons/io5";

// Translate
import { useTranslation } from "react-i18next";

// API
import { updatePassword } from "bto_now/api/seguranca/updatePassword";
import  { ValidatePassword } from "utils/password/isValidPassword";
  
const NewPassword = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const [load, setLoad] = useState(false);

  const [form] = Form.useForm(); 

  const onSend = async (values: any) => {
    try {
      setLoad(true);

      const { PASSWORD } = values;

      const query = new URLSearchParams(location.search);
      const token = query.get('token');

      const params = {
        token: token,
        password: PASSWORD
      }

      const { data } = await updatePassword(params);

      if (data.sucesso) {
        message.success("Senha atualizada com sucesso.");
        navigate('/')
      } else {
        message.error("Erro ao trocar senha.");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoad(false);
    }
  };

  const validatePassword = async (_: any, value: string) => {
      if (!value) {
        return Promise.reject(new Error(t('Campo obrigatórios')));
      }

      const minMaxLengthRegex = /^[A-Za-z\d!@#$%^&*()]{6,12}$/;
      const lowercaseRegex = /[a-z]/;
      const uppercaseRegex = /[A-Z]/;
      const numberRegex = /\d/;
      const specialCharRegex = /[!@]/;
      const onlyOneSpecialCharRegex = /^[^!@#$%^&*()]*[!@#$%^&*()][^!@#$%^&*()]*$/;
      
      
        if (!minMaxLengthRegex.test(value)) {
          return Promise.reject(new Error('A senha deve ter entre 6 e 12 caracteres.'));
        }
      
        if (!lowercaseRegex.test(value)) {
          return Promise.reject(new Error(t('A senha deve conter pelo menos uma letra minúscula.')));
        }
      
        if (!uppercaseRegex.test(value)) {
          return Promise.reject(new Error(t('A senha deve conter pelo menos uma letra maiúscula.')));
        }
      
        if (!numberRegex.test(value)) {
          return Promise.reject(new Error(t('A senha deve conter pelo menos um número.')));
        }
      
        if (!specialCharRegex.test(value)) {
          return Promise.reject(new Error(t('A senha deve conter exatamente um caractere especial (entre: ! @)')));
        }
      
      
        if (!onlyOneSpecialCharRegex.test(value)) {
          return Promise.reject(new Error(t('A senha não pode conter mais de um caractere especial.')));
        }
      

      if (value && value !== form.getFieldValue('PASSWORD')) {
          return Promise.reject(new Error(t('As senhas não coincidem')));
      }
    
      return Promise.resolve();
  };

  return (
    <Row className="login-content" align={'middle'} justify={'center'}>
      <Col md={12} xs={24} className='login-logo-col'>
        <Image src={logo} preview={false} className='login-logo' />
        <Row gutter={[16, 16]}>
          <Col><div className='login-social'><IoLogoFacebook /></div></Col>
          <Col><div className='login-social'><IoLogoInstagram /></div></Col>
          <Col><div className='login-social'><IoLogoTwitter /></div></Col>
        </Row>
      </Col>
      <Col md={12} xs={24}>
        <div className='login-form'>
          <Typography className='title-login'>{t("Nova senha")}</Typography>
          <Typography className='subtitle-login'>{t("Por favor, preencha sua nova senha abaixo")}</Typography>
          {/* O form precisa receber a instância criada pelo useForm */}
          <Form form={form} layout='vertical' onFinish={onSend}>
            <Form.Item
              className='login-input'
              name="PASSWORD"
              rules={[
                  { validator: validatePassword }
              ]}
            >
              <Input
                addonBefore={<IoLockClosedOutline />}
                type='password'
                size='large'
                placeholder='Nova senha'
                className='input-outlined'
              />
            </Form.Item>
            <Form.Item
              className='login-input'
              name="REPEATPASSWORD"
              dependencies={['PASSWORD']}
              rules={[
                { validator: validatePassword }
              ]}
            >
              <Input
                addonBefore={<IoLockClosedOutline />}
                type='password'
                size='large'
                placeholder='Repetir senha'
                className='input-outlined'
              />
            </Form.Item>
            <center>
              <Button
                loading={load}
                htmlType='submit'
                className='login-button'
                type='primary'
              >
                {t("Salvar")}
              </Button>
            </center>
          </Form>
        </div>
      </Col>
    </Row>
  );
};

export default NewPassword;
